import React, { FC } from 'react';
import cn from 'classnames';
import RevertBlock from 'components/RevertBlock';
import Label from '../Input/Label';
import css from './styles.module.scss';
import i18n from '../../../i18n';
import { toolTipType } from '../Input/types';
import CreatorTag from './components/CreatorTag';
import useTagsAutoSave, {
  TFUseTagsAutoSaveProps,
} from './hooks/useTagsAutoSave';
import Tag from './components/Tag';
import ErrorText from '../ErrorText';
import TableView from './components/TableView';

type TagAutoSaveProps = TFUseTagsAutoSaveProps & {
  /** Вывод тултипа со ссылкой */
  tooltip?: toolTipType;
  /** Список отличающихся тегов после изменения */
  isSaved?: string[];
  isError?: boolean;
  errorText?: string;
  /** горизонтальный стиль */
  theme?: string;
  isDisable?: boolean;
  maxLength?: number;
  /** Табличный вид */
  tableView?: boolean;
};

const TagAutoSave: FC<TagAutoSaveProps> = ({
  tooltip,
  tagsData,
  handlerData,
  LTUPast,
  handleRevert,
  isSaved,
  isError,
  isErrorTags,
  errorText,
  theme,
  isDisable,
  maxLength,
  tableView = false,
}) => {
  const {
    tags,
    handlerRemoveTag,
    handlerTagAdd,
    handlerUpdateTag,
    counter,
    handlerLocalRevert,
    isTagsWithError,
    search,
    setSearch,
    startCreateList,
    setStartCreateList,
    textareaValue,
    setTextareaValue,
    handlerTagListAdd,
    closingAmin,
  } = useTagsAutoSave({
    tagsData,
    handlerData,
    LTUPast,
    handleRevert,
    isErrorTags,
  });
  return (
    <div
      className={cn(css.tagsWrapper, {
        horizontal: theme === 'horizontal',
      })}
    >
      <Label
        tooltip={tooltip}
        label={i18n.t('campaigns_page.campaign_settings.tags_label')}
      />
      {counter > 0 && handleRevert && (
        <RevertBlock counter={counter} onClick={handlerLocalRevert} />
      )}
      {isError && isTagsWithError && (
        <div className={css.errorBlock}>
          <ErrorText hideIcon text={`${errorText}`} />
        </div>
      )}
      {tableView ? (
        <TableView
          tags={tags}
          setStartCreateList={setStartCreateList}
          startCreateList={startCreateList}
          search={search}
          setSearch={setSearch}
          maxLength={maxLength}
          handlerRemoveTag={handlerRemoveTag}
          handlerUpdateTag={handlerUpdateTag}
          counter={counter}
          isSaved={isSaved}
          isErrorTags={isErrorTags}
          isDisable={isDisable}
          textareaValue={textareaValue}
          setTextareaValue={setTextareaValue}
          handlerTagListAdd={handlerTagListAdd}
          closingAmin={closingAmin}
        />
      ) : (
        <div className={css.tags}>
          <CreatorTag
            handlerAction={handlerTagAdd}
            isDisable={isDisable}
            maxLength={150}
          />
          {tags.map((value, index) => (
            <Tag
              key={value}
              value={value}
              maxLength={maxLength}
              handlerClose={handlerRemoveTag}
              handlerUpdate={handlerUpdateTag}
              index={index}
              isSaved={counter > 0 && !!isSaved && isSaved.indexOf(value) > -1}
              isError={!!isErrorTags && isErrorTags.indexOf(value) > -1}
              isDisable={isDisable}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TagAutoSave;
