import React, { FC } from 'react';
import cn from 'classnames';
import { FiX } from 'react-icons/fi';
import Button from 'components/UI/Buttons/buttons';
import { getRandomNumber } from 'utils/useGeneralUtils';
import css from '../../styles.module.scss';
import cssCreate from '../CreatorTag/styles.module.scss';
import i18n from '../../../../../i18n';
import Tag from '../Tag';

interface ITableViewProps {
  tags: string[];
  setStartCreateList: React.Dispatch<React.SetStateAction<boolean>>;
  startCreateList: boolean;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  maxLength: number | undefined;
  handlerRemoveTag: (tag: string) => void;
  handlerUpdateTag: (tag: string, index: number) => void;
  counter: number;
  isSaved: string[] | undefined;
  isErrorTags: string[] | undefined;
  isDisable: boolean | undefined;
  textareaValue: string;
  setTextareaValue: React.Dispatch<React.SetStateAction<string>>;
  handlerTagListAdd: (taglist: string) => void;
  closingAmin: boolean;
}

const TableView: FC<ITableViewProps> = ({
  tags,
  setStartCreateList,
  startCreateList,
  search,
  setSearch,
  maxLength,
  handlerRemoveTag,
  handlerUpdateTag,
  counter,
  isSaved,
  isErrorTags,
  isDisable,
  textareaValue,
  setTextareaValue,
  handlerTagListAdd,
  closingAmin,
}) => (
  <div className={css.tags_shell}>
    <div
      className={cn(css.tags, 'isTableView', {
        isEmptyBody: tags && tags.length === 0,
      })}
    >
      <div className={css.tags_header}>
        <div
          className={cn(cssCreate.add, 'isToggle', css.tags_add, {
            _isRotate: startCreateList,
          })}
          onClick={() => {
            setStartCreateList(!startCreateList);
            setTextareaValue(tags.join('\n'));
          }}
        >
          <FiX size={24} />
        </div>
        <input
          className={css.tags_search}
          placeholder={i18n.t(`targetings.search`)}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {tags && tags.length !== 0 && (
        <div className={css.tags_body}>
          <div className={css.tags_bodyWrap}>
            {tags &&
              tags
                .filter((item) => item.includes(search))
                .map((el, index) => (
                  <Tag
                    key={`${el}${getRandomNumber()}`}
                    value={el}
                    maxLength={maxLength}
                    handlerClose={handlerRemoveTag}
                    handlerUpdate={handlerUpdateTag}
                    index={index}
                    isSaved={
                      counter > 0 && !!isSaved && isSaved.indexOf(el) > -1
                    }
                    isError={!!isErrorTags && isErrorTags.indexOf(el) > -1}
                    isDisable={isDisable}
                  />
                ))}
          </div>

          {tags &&
            tags.filter((item) => item.includes(search)).length === 0 &&
            tags.length !== 0 && (
              <div className={css.tags_empty}>
                {i18n.t(`campaigns_page.campaigns_comments.no_search`)}
              </div>
            )}
        </div>
      )}
    </div>
    <div
      className={cn(css.tags_enterList, {
        isOpen: startCreateList,
        isAbsolute: tags && tags.length !== 0,
        isClosingAmin: closingAmin,
      })}
    >
      <div className={css.input}>
        <textarea
          className={css.textarea}
          placeholder="Введите теги через enter"
          value={textareaValue}
          onChange={(e) => setTextareaValue(e.target.value)}
        />
      </div>
      <div className={css.action}>
        {textareaValue !== '' && (
          <Button
            title={i18n.t(`save_btn`)}
            buttonAttributes={{
              style: {
                height: '40px',
              },
              onClick: () => handlerTagListAdd(textareaValue),
            }}
          />
        )}
      </div>
      <div className={css.hint}>
        Можно добавить теги списком, используя перенос строки.
      </div>
    </div>
  </div>
);

export default TableView;
