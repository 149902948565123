import React, { useRef, useState, useEffect, ReactNode, FC } from 'react';
import cn from 'classnames';
import { ScrollSync, ScrollSyncNode } from 'scroll-sync-react';
import CreativeListFilter from 'components/CreativeListFilter';
import TableFilter from 'components/UI/TableFilter';
import Checkbox from 'components/UI/Checkbox/checkbox';
import OnlyOwner from 'components/OnlyOwner';
import { FiExternalLink } from 'react-icons/fi';
import { useUserTheme } from 'domains/user/model/selectors';
import TranslationsListFilter from 'components/TranslationsListFilter';
import { TFieldStatistics } from 'domains/campaign/types';
import { RGetCreativeStatistics } from 'domains/creative/types';
import { formatStatistics } from 'domains/campaigns/model/utils/formatStatistics';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import css from './styles.module.scss';
import { useOnScroll } from '../../hooks/useOnScroll';
import {
  useCreativeListFilter,
  useCreativeSortInfo,
} from '../../domains/creative/hooks';
import i18n from '../../i18n';
import filterSvg from '../TranslationsListFilter/all.svg';

type Props = {
  filterDataLength: number | null;
  header: string[];
  colClass: Record<string, string>;
  sumStatistics: RGetCreativeStatistics | null;
  sumTitle: ReactNode;
  isThemeExtended?: boolean;
  keysFields: TFieldStatistics[];
  isThemeFull?: boolean;
  onFilterToggleDropdown: (v: boolean) => void;
  allCreativeChecked: boolean;
  allPartialCreativeChecked: boolean;
  handlerAllCheckedCreatives: (value: boolean) => void;
  filterCreoTranslation?: (key: string) => void;
  currentFilter: string;
  isLoadingTranslationFilter: boolean;
};

const StatisticsTable: FC<Props> = ({
  filterDataLength,
  header,
  children,
  colClass,
  sumStatistics,
  sumTitle,
  isThemeExtended,
  isThemeFull,
  keysFields,
  onFilterToggleDropdown,
  allCreativeChecked,
  allPartialCreativeChecked,
  handlerAllCheckedCreatives,
  filterCreoTranslation,
  currentFilter,
  isLoadingTranslationFilter,
}) => {
  const [contentWidth, setContentWidth] = useState<number>(0);
  const [long, setLong] = useState<boolean>(false);
  const [showScroll, setShowScroll] = useState<boolean>(false);
  const tableRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const headerScrollRef = useRef<HTMLDivElement>(null);
  const scrollbarRef = useRef<HTMLDivElement>(null);

  const { getValueFromSettingForKey } = useUserTheme();

  const { fixed } = useOnScroll(75);

  const {
    handlerSort: handleCreative,
    key: activeKeyCreative,
    sort: sortCreative,
    isShow,
  } = useCreativeSortInfo();

  const { listFilter } = useCreativeListFilter();

  // Определяем необходимость показа нижнего скроллбара
  useEffect(() => {
    if (
      tableRef.current &&
      tableRef.current.offsetHeight >
        document.documentElement.clientHeight - 270
    ) {
      setLong(true);
    } else {
      setLong(false);
    }
  }, [children]);

  useEffect(() => {
    setTimeout(() => {
      if (contentRef.current) {
        setContentWidth(contentRef.current.scrollWidth);

        if (contentRef.current.offsetWidth !== contentRef.current.scrollWidth) {
          setShowScroll(true);
        } else setShowScroll(false);
      }
    }, 500);
  }, [contentRef, children, isThemeExtended]);

  const getHeader = () => {
    const fixedFields: Props['header'] = [];
    const scroll: Props['header'] = [];

    // eslint-disable-next-line array-callback-return
    header.map((item, index: number) => {
      if (index < 2) {
        fixedFields.push(item);
      } else {
        scroll.push(item);
      }
    });

    return (
      <div
        className={cn('container resized', {
          extend: isThemeExtended,
          full: isThemeFull,
        })}
      >
        <div className={css.fixed}>
          <div className={css.select__box}>
            <OnlyOwner>
              <Checkbox
                label=""
                className={css.checkbox}
                isPartiallyChecked={allPartialCreativeChecked}
                inputAttributes={{
                  type: 'checkbox',
                  checked: allCreativeChecked,
                  onChange: () =>
                    handlerAllCheckedCreatives(
                      allCreativeChecked || allPartialCreativeChecked,
                    ),
                  disabled: listFilter === 'DELETED',
                }}
                onlyBox
              />
            </OnlyOwner>
          </div>
          <div className={css.num}>
            <TableFilter
              data="number"
              title="№"
              activeKey={activeKeyCreative}
              handleSort={handleCreative}
              sort={sortCreative}
              isShow
            />
          </div>
          <CreativeListFilter
            onFilterToggleDropdown={onFilterToggleDropdown}
            className={css.listFilter}
          />

          <div className={css.text}>
            <div className={css.title}>
              {fixedFields.map((title, index) => (
                <TableFilter
                  key={title}
                  data={index === 0 ? 'type' : 'name'}
                  title={title}
                  activeKey={activeKeyCreative}
                  handleSort={handleCreative}
                  sort={sortCreative}
                  isShow={index === 0 ? false : isShow}
                />
              ))}
            </div>
            <div className={css.sum}>{sumTitle}</div>
          </div>
        </div>
        <ScrollSyncNode>
          <div className={css.scroll} ref={headerScrollRef}>
            {!!filterDataLength &&
              !!scroll.length &&
              scroll.map((title, index: number) => (
                <div
                  className={cn(
                    css.item,
                    // eslint-disable-next-line no-nested-ternary
                    keysFields.some((field) => field.title === title)
                      ? colClass[
                          keysFields.filter((field) => field.title === title)[0]
                            .key
                        ] !== undefined
                        ? colClass[
                            keysFields.filter(
                              (field) => field.title === title,
                            )[0].key
                          ]
                        : `size-${
                            keysFields.filter(
                              (field) => field.title === title,
                            )[0].isLengthTitle
                          }`
                      : ``,
                    {
                      _isGrey: title.trim().toLocaleLowerCase() === 'bid',
                    },
                  )}
                  key={title}
                >
                  <div className={css.title}>
                    {keysFields[index] && keysFields[index].key && (
                      <TableFilter
                        data={`${keysFields[index].key}`}
                        title={title}
                        activeKey={activeKeyCreative}
                        handleSort={handleCreative}
                        sort={sortCreative}
                        isShow
                      />
                    )}
                  </div>
                  <div className={css.sum}>
                    {!!filterDataLength &&
                      sumStatistics &&
                      keysFields[index] &&
                      formatStatistics(
                        sumStatistics[keysFields[index].key],
                        true,
                      )}
                  </div>
                </div>
              ))}
          </div>
        </ScrollSyncNode>
        <div
          className={cn(css.options, {
            fixed,
            isScroll: showScroll,
          })}
        >
          {getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
            !getValueFromSettingForKey('ShowBroadcastStatus') && (
              <>
                <TooltipPoratal
                  white
                  hintWidth={260}
                  hint={
                    <>
                      <span className={css.statusText}>
                        {i18n.t(`translations.title_hint_creo`)}
                      </span>
                      <a
                        href="https://terratraf.readme.io/docs/td-translation-status"
                        target="_blank"
                        className={css.statusLink}
                        rel="noreferrer"
                      >
                        <span>{i18n.t(`translations.title_hint_link`)}</span>
                        <FiExternalLink color="#025DFF" />
                      </a>
                    </>
                  }
                >
                  {isLoadingTranslationFilter ? (
                    <img
                      src={filterSvg}
                      className={cn('icon', css.filterList)}
                      alt="filter all"
                    />
                  ) : (
                    <TranslationsListFilter
                      currentFilter={currentFilter}
                      filterHandler={filterCreoTranslation}
                    />
                  )}
                </TooltipPoratal>
              </>
            )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={cn(css.table, {
        _isFixed: fixed,
        _isLong: long,
        _isFullTitle: isThemeExtended || isThemeFull,
        _isThemeFull: isThemeFull,
      })}
      ref={tableRef}
    >
      <ScrollSync>
        <>
          <div className={css.header_placeholder}>
            <div className={css.header}>{getHeader()}</div>
          </div>

          <div
            className={cn('container resized', {
              extend: isThemeExtended,
              full: isThemeFull,
            })}
          >
            <div className={css.body}>
              <ScrollSyncNode>
                <div className={css.content}>
                  <div ref={contentRef}>{children}</div>
                </div>
              </ScrollSyncNode>
            </div>
          </div>
          {showScroll && (
            <div className={css.scrollbar}>
              <div
                className={cn('container resized', {
                  extend: isThemeExtended,
                  full: isThemeFull,
                })}
              >
                <ScrollSyncNode>
                  <div className={css.bar} ref={scrollbarRef}>
                    <div
                      className={css.content}
                      style={{ width: `${contentWidth}px` }}
                    />
                  </div>
                </ScrollSyncNode>
              </div>
            </div>
          )}
        </>
      </ScrollSync>
    </div>
  );
};

export default StatisticsTable;
